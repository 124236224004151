<template>
  <div class="container">
    <header-login></header-login>

    <!-- <div class="container is-max-desktop">
  <div class="notification">
    This container has a <code>max-width</code> of <code>$desktop - $container-offset</code> on widescreen and fullhd.
  </div>
</div> -->

    <div class="container is-max-desktop card-body">
      <div class="column">
        <div class="" style="padding-bottom: 25px">
          <section class="container has-text-left is-primary">
            <!-- <b-field>
              <b-input
                v-model="preFixName"
                placeholder="คำนำหน้า"
                type="text"
                icon="account"
                rounded
              >
              </b-input>
            </b-field> -->

            <b-field>
              <b-input
                v-model="firstName"
                :placeholder="person.fname.en"
                type="text"
                icon="account"
                required
                rounded
              >
              </b-input>
            </b-field>

            <b-field>
              <b-input
                v-model="lastName"
                :placeholder="person.lname.en"
                type="text"
                icon="account"
                required
                rounded
              >
              </b-input>
            </b-field>

            <b-field>
              <b-input
                v-model="workplace"
                :placeholder="person.workplace.en"
                type="text"
                icon="ballot"
                required
                rounded
              >
              </b-input>
            </b-field>

            <b-field>
              <b-input
                :placeholder="person.email.en"
                v-model="email"
                type="email"
                icon="email"
                required
                rounded
              >
              </b-input>
            </b-field>

            <!-- <b-field style="visibility: hidden; display: none"> -->
            <b-field style="" v-if="!this.$store.getters.flag_fix_password">
              <b-input
                v-model="password"
                type="password"
                icon="lock"
                placeholder="Password"
                password-reveal
                required
                rounded
              >
              </b-input>
            </b-field>

            <!-- <b-field style="visibility: hidden; display: none"> -->
            <b-field style="" v-if="!this.$store.getters.flag_fix_password">
              <b-input
                v-model="passworconfrimed"
                :rules="[comparePasswords]"
                type="password"
                icon="lock"
                placeholder="password confirmed"
                password-reveal
                required
                rounded
              >
              </b-input>
            </b-field>

            <!-- <b-field>
              <b-input
                v-model="telephone"
                placeholder="*เบอร์ติดต่อ"
                type="text"
                icon="phone"
                required
                rounded
              >
              </b-input>
            </b-field> -->

            <!-- <b-field>
              <b-input
                v-model="address"
                placeholder="*ที่อยู่จัดส่งกิมมิค"
                type="text"
                icon="ballot"
                required
                rounded
              >
              </b-input>
            </b-field> -->

            <b-field>
              <b-input
                v-model="ceCode"
                :placeholder="person.ceCode.en"
                type="text"
                icon="ballot"
                required
                rounded
              >
              </b-input>
            </b-field>

            <b-field grouped position="is-right" class="fontStyle">
              <label @click="goLoginPage()" style="cursor: pointer">
                {{ person.btLogin.en }}</label
              >
            </b-field>

            <b-button
              style="background-color: #2c296b"
              @click="onRegister"
              class="button is-info"
              :loading="btLoading"
              :disabled="btLoading"
              expanded
              rounded
              >{{ person.btRegister.en }}</b-button
            >

            <span class="has-text-danger is-italic"> {{ errorMsg }}</span>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
// import ErrorMessageService from "@/service/ErrorMessageService";
import HeaderLogin from "./HeaderLogin.vue";
import axios from "axios";
import { store } from "@/store/store";

export default {
  components: {
    HeaderLogin,
  },
  name: "RegisterForm",
  props: {
    // msg: String,
  },
  data() {
    return {
      // email: "test08@catsmodish.com",
      // passworconfrimed: "admin12345",
      // password: "admin12345",
      // firstName: "firstName",
      // lastName: "lastName",
      // workplace: "workplace",
      // telephone: "0123456789",

      person: {
        fname: {
          th: "*ชื่อ",
          en: "*Name",
        },
        lname: {
          th: "*นามสกุล",
          en: "*Surname",
        },
        workplace: {
          th: "*คลินิค/โรงพยาบาล",
          en: "*Clinic/Hospital",
        },
        email: {
          th: "*อีเมล์",
          en: "*Email",
        },
        ceCode: {
          th: "*เลขที่ใบประกอบวิชาชีพ",
          en: "*Medical License",
        },
        btRegister: {
          th: "ลงทะเบียน",
          en: "Register",
        },
        btLogin: {
          th: "ล็อคอินเข้างาน",
          en: "Sign in",
        },
      },

      preFixName: "",

      email: "",
      passworconfrimed: "",
      password: "",
      firstName: "",
      lastName: "",
      workplace: "",
      telephone: " ",

      address: " ",
      ceCode: "",

      token: "",
      errorMsg: "",
      btLoading: false,

      templeteColor: this.$store.getters.templeteBgColor,
    };
  },
  computed: {
    comparePasswords() {
      return this.password === this.passworconfrimed
        ? true
        : "Password and confirm password don't match";
    },
  },
  methods: {
    success(msg) {
      // const notif =
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 10000,
      });
      // notif.$on("close", () => {
      // this.$buefy.notification.open("Custom notification closed!");
      // this.$router.push({ path: "/" });
      // });
    },

    danger(msg) {
      // const notif =
      this.$buefy.notification.open({
        duration: 10000,
        message: msg,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
      // notif.$on("close", () => {
      //   this.$buefy.notification.open("Custom notification closed!");
      //   this.$router.push({ path: "/" });
      // }
      // );
    },

    clearIconClick() {
      this.email = "";
      alert("Email cleared!");
    },
    goLoginPage() {
      // alert("goLoginPage");
      this.$router.push({ path: "/" });

      // if(this.$store.getters.flag_fix_password){
      //   this.password = this.$store.getters.fix_password;
      // }

      // alert(this.$store.getters.fix_password );
    },

    onRegister() {
      this.btLoading = true;

      // if (this.ceCode == "" || this.ceCode == null) {
      //   this.ceCode = " ";
      // }

      if (this.$store.getters.flag_fix_password) {
        this.password = this.$store.getters.fix_password;
        this.passworconfrimed = this.$store.getters.fix_password;
      }

      // alert(this.password );

      let user = {
        username: this.email,
        password: this.password,
        firstName: this.preFixName + " " + this.firstName,
        lastName: this.lastName,
        workplace: this.workplace,
        telephone: this.telephone,
        address: this.address,
        ceCode: this.ceCode,
      };
      // alert(this.email.search("@"));
      this.validateEmail();
      this.validateCEcode();
      this.validateComparePasswords();

      console.log("user : " + JSON.stringify(user, null, 2));
      if (this.errorMsg == "") {
        this.register(user);
      } else {
        this.btLoading = false;
      }

      // this.register(user);

      // UserService.register(user);
      // this.$store.dispatch("addUser",user);
    },

    // validateEmail() {
    //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
    //     this.msg["email"] = "Please enter a valid email address";
    //   } else {
    //     this.msg["email"] = "";
    //   }
    // },

    validateCEcode() {
      if (this.ceCode == "" || this.ceCode == null) {
        this.errorMsg += " Please enter license number";
      }
    },

    validateEmail() {
      // alert(this.email.search("."));
      // alert(this.email.search("@"));
      // alert(this.email.length);

      // let regex = new RegExp("/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/");
      if (this.email.search("@") <= 0) {
        // this.msg["email"] = "Please enter a valid email address";
        this.errorMsg = "Please enter @ a valid email address";
        // } else if(this.email.search(".") <= 0) {
        //   // this.msg["email"] = "";
        //   this.errorMsg = "Please enter . a valid email address";
      } else if (this.email.length <= 3) {
        // this.msg["email"] = "";
        this.errorMsg = "Please enter email address more than 3 character";
      } else {
        // this.msg["email"] = "";
        this.errorMsg = "";
      }
    },

    validateComparePasswords() {
      if (this.password != this.passworconfrimed) {
        this.errorMsg = "Password and confirm password don't match";
      } else {
        this.errorMsg = "";
      }
    },

    async register(user) {
      // https://service.promptevent.com/api/v1/virtual/user-register
      // const url = Settings.url + "/api/v1/virtual/user-register";
      const url = store.getters.url + "/api/v1/virtual/user-register";
      let message = "";

      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          // Authorization: `Bearer sss`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: user.username,
        password: user.password,
        firstName: user.firstName,
        middleName: "middleName",
        lastName: user.lastName,
        careerPosition: "position",
        workplace: user.workplace,
        telephone: user.telephone,
        address: user.address,
        ceCode: user.ceCode,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then(
          (response) => {
            // your code
            // console.log(
            //   "response.data : " + JSON.stringify(response.data, null, 2)
            // );

            // this.errorMsg = response.data.statusDesc;

            if (response.data.status == "failure") {
              if (response.data.statusDesc == "Username is not available") {
                this.errorMsg = "" + "Username already register";
                this.success(this.errorMsg);
              } else {
                this.errorMsg = "Error : " + response.data.statusDesc;
                this.danger(this.errorMsg);
              }

              this.btLoading = false;

              //  var that = this;
              //   setTimeout(function () {
              //     that.success("setTimeout go to Login ");
              //     that.$router.push({ path: "/" });
              //   }, 2500);

              // ErrorMessageService.danger(this.errorMsg);
              console.log("data.status : " + response.data.status);
            } else if (response.data.status == "success") {
              this.errorMsg = response.data.status;

              this.success("Register success, please wait admin active user");

              // ErrorMessageService.success(
              //   "Register success, please wait admin active user"
              // );
              console.log("data : " + response.data);
              setTimeout(() => this.goLoginPage(), 5000);
              // this.$router.push({ path: "/LoginPage" });
            } else {
              // error request
              console.log("data : " + response.data);

              // this.$router.push({ path: "/" });
            }
          },

          // this.$router.push({ path: "/" })
          console.log
        )
        .catch(console.log);
      return message;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.card-body {
  align-self: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 500px) {
  .card-body {
    align-self: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 500px;
  }
}

.fontStyle {
  color: var(--templete-font-color);
}
</style>
