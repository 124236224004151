

<template>
  
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>


    <div class="card-body"><strong> Scan QR CODE Check in </strong></div>


    <section class="container has-text-left is-primary">

        
  <div style="height: 300px;width: 300px;">
   

    <qrcode-stream :key="_uid" :track="this.paintOutline" @init="logErrors" />
  </div>

    </section>

    <footer-component></footer-component>
  </div>

</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

// import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import adminNavComponent from "./adminNav.vue";

import UserService from "@/service/UserService";
import { store } from "@/store/store";
import DebugService from "@/service/DebugService";

import QRCodeService from "@/service/QRCodeService";

export default {

  components: { QrcodeStream, 
    adminNavComponent,
    FooterComponent,
    HeaderComponent, },

  data () {
    const options = [
      { text: "nothing (default)", value: undefined },
      { text: "outline", value: this.paintOutline },
      { text: "centered text", value: this.paintCenterText },
      { text: "bounding box", value: this.paintBoundingBox },
    ]

    const selected = options[1]

    return { selected, options }
  },
  async created() {
    console.clear();
    console.log("created Scan QR CODE");
    this.checkSesseions();

  },

  methods: {
    paintOutline (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
        // alert(detectedCode.rawValue);
       this.CheckinUser(detectedCode.rawValue)
      }
    },

    
    danger(msg) {
      this.$buefy.notification.open({
        duration: 5000,
        message: msg,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
    },

    
    success(msg) {
      this.$buefy.notification.open({
        duration: 5000,
        message: msg,
        position: "is-bottom-right",
        type: "is-success",
        hasIcon: true,
      });
    },

    CheckinUser(codeCheckin) {

      if(confirm("Do you want to Check in?")){
                 // on confirmed 
        //  alert(codeCheckin);
        //  this.success("check in complete");

         
          let statusLogin = QRCodeService.CheckinUser(codeCheckin);
          statusLogin.then((status) => {
            // alert(status);
            DebugService.printConsole("status login ", status);
            // let statusLogin = false;
            if (!status) {
              // set state unload
              // this.danger(this.$store.getters["userLogin/messageErrorLogin"]);
              this.danger("QR CODE ไม่ถูกต้อง");
              // this.$router.push({ path: "/" });
            } else {
              
              this.success("ลงทะเบียนสำเร็จ");
              

              // this.$router.push({ path: "/HomePage" });
            }
          });


        //  alert("check in complete");
      }
    },

    
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },

    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },

    paintCenterText (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode

        const centerX = boundingBox.x + boundingBox.width/ 2
        const centerY = boundingBox.y + boundingBox.height/ 2

        const fontSize = Math.max(12, 50 * boundingBox.width/ctx.canvas.width)
        console.log(boundingBox.width, ctx.canvas.width)

        ctx.font = `bold ${fontSize}px sans-serif`
        ctx.textAlign = "center"

        ctx.lineWidth = 3
        ctx.strokeStyle = '#35495e'
        ctx.strokeText(detectedCode.rawValue, centerX, centerY)

        ctx.fillStyle = '#5cb984'
        ctx.fillText(rawValue, centerX, centerY)
      }
    },

    logErrors (promise) {
      promise.catch(console.error)
    }
  }

}
</script>