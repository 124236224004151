import Vue from 'vue'
import App from './App.vue'
import { store } from "./store";
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
// import "@ocrv/vue-tailwind-pagination/styles";
import VueRouter from 'vue-router'

/* components */
import LoginPage from './components/LoginPage'
import RegisterPage from './components/RegisterPage'
import HomePage from './components/HomePage'
import StreamPage from './components/StreamPage'
import InfoPage from './components/InfoPage'
import EvaluationPage from './components/EvaluationPage'
import AdminUserPage from './components/AdminUserPage'
import SummaryQuestionPage from './components/SummaryQuestionPage'
import SummaryPollPage from './components/SummaryPollPage'
import SummaryEvaluationPage from './components/SummaryEvaluationPage'

import CreateChannelPage from './components/superAdmin/CreateChannelPage'
import UpdateCFPage from './components/superAdmin/UpdateCFPage'
import UpdateChannelPage from './components/TestChart'
import AdminManageQuestionPage from './components/AdminManageQuestionPage'

import ReportLiveStreamingViewerPage from './components/ReportLiveStreamingViewerPage'
import ReportPlayBackViewerPage from './components/ReportPlayBackViewerPage'
import ReportQAPage from './components/ReportQAPage'
import ReportPollPage from './components/ReportPollPage'
import ReportEvaluationPage from './components/ReportEvaluationPage'


import RedirectEAPage from './components/RedirectEAPage'

import ScanQRCODEPage from './components/ScanQRCODE'
import CreateQRCODEPage from './components/ScanQRCODECreate'



const routes = [
  // { path: '/', component: VideoPlayerPage1 },
  // { path: '/', component: TestAnimation },
  { path: '/', component: LoginPage },


  { path: '/LoginPage', component: LoginPage },
  
  { path: '/RegisterPage', component: RegisterPage },
  { path: '/HomePage', component: HomePage },
  { path: '/StreamPage/:id', component: StreamPage },
  { path: '/InfoPage', component: InfoPage },
  { path: '/EvaluationPage/', component: EvaluationPage },
  { path: '/AdminUserPage/', component: AdminUserPage },
  { path: '/SummaryQuestionPage/', component: SummaryQuestionPage },
  { path: '/SummaryPollPage/', component: SummaryPollPage },
  { path: '/SummaryEvaluationPage/', component: SummaryEvaluationPage },

  { path: '/ReportLiveStreamingViewerPage/', component: ReportLiveStreamingViewerPage },
  { path: '/ReportPlayBackViewerPage/', component: ReportPlayBackViewerPage },
  { path: '/ReportQAPage/', component: ReportQAPage },
  { path: '/ReportPollPage/', component: ReportPollPage },
  { path: '/ReportEvaluationPage/', component: ReportEvaluationPage },

  { path: '/CreateChannelPage/', component: CreateChannelPage },
  { path: '/UpdateChannelPage/', component: UpdateChannelPage },
  { path: '/UpdateCFPage/', component: UpdateCFPage },  
  { path: '/AdminManageQuestionPage/', component: AdminManageQuestionPage },
  
  { path: '/RedirectEAPage/', component: RedirectEAPage },


  { path: '/ScanQRCODEPage/', component: ScanQRCODEPage },  
  { path: '/CreateQRCODEPage/', component: CreateQRCODEPage },

  
  
  // { path: '/VideoPlayerPage', component: VideoPlayerPage },
]

// import TestAnimation from './components/TestAnimation'


// import sidebar from './components/sidebar'

// import StreamPage from './components/VideoPlayer2'

//test video
// import VideoPlayerPage1 from './components/VideoPlayer1'

// import router from './router'

Vue.use(VueRouter)


// import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'

// Vue.use(Vuetify)

import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);


// import Vue from 'vue'
// import {VideoPlayer} from 'vue-videojs7'

import VueVideoPlayer from 'vue-video-player'
import 'videojs-contrib-hls'
// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

// import Videojss from 'video.js'
// import 'videojs-contrib-hls'

let hls = require('videojs-contrib-hls')
Vue.use(hls)
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)

Vue.use(require('vue-moment'));

// import {VideoPlayer} from 'vue-videojs7'

// Vue.use(VideoPlayer, /* {
//   options: global default videojs options,
//   events: global videojs videojs events
// } */)


const router = new VueRouter({ routes })


// import {VideoPlayer} from 'vue-videojs7'

// Vue.use(VideoPlayer, /* {
//   options: global default videojs options,
//   events: global videojs videojs events
// } */)



Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
