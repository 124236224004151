import axios from "axios";
import { store } from "@/store/store";
import DebugService from "@/service/DebugService";

export default {


  // https://dev.catsmodish.com/api/v1/virtual/qrcode-check
  // API_QR_02 QRCodeCheckInt

  async CheckinUser(codeCheckIn) {
    const url = store.getters.url + "/api/v1/virtual/qrcode-check";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      qrcodeKey: codeCheckIn,
      checkType: "2",
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("on check in by qr code : ", response.data);
          // console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);
            store.commit("userLogin/SET_MESSAGE_ERROR", "login failure");
            store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            // store.commit("userLogin/SET_MESSAGE_ERROR", "login success");
            // alert( JSON.stringify(response.data, null, 2));

            statusLogin = true;

            store.commit("userLogin/SET_MESSAGE_ERROR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("userLogin/SET_MESSAGE_ERROR", "error request");
            statusLogin = false;
            return false;
          }
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },

  // https://dev.catsmodish.com/api/v1/virtual/qrcode-create
  // API_QR_01 Create QRCode

  async CreateAllQRcode() {
    const url = store.getters.url + "/api/v1/virtual/qrcode-create";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // CREATE ALL QR CODE
    let bodyParameters = {
      "mode": "A",
      "usernames": ["cgm.admin01@catsmodish.com"]
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("on check in by qr code : ", response.data);
          console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);
            store.commit("userLogin/SET_MESSAGE_ERROR", "login failure");
            store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            // store.commit("userLogin/SET_MESSAGE_ERROR", "login success");
            // alert( JSON.stringify(response.data, null, 2));
            statusLogin = true;

            store.commit("userLogin/SET_MESSAGE_ERROR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            // console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("userLogin/SET_MESSAGE_ERROR", "error request");
            statusLogin = false;
            return false;
            // this.$router.push({ path: "/" });
          }

          // save token

          // router to home

          // console.log;
          // alert(this.errorMsg);
          // this.$router.push({ name: "home" });
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },


  
  // https://dev.catsmodish.com/api/v1/virtual/qrcode-create
  // API_QR_01 Create QRCode

  async CreateUserQRcode(arryUser) {
    const url = store.getters.url + "/api/v1/virtual/qrcode-create";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // CREATE ALL QR CODE
    let bodyParameters = {
      "mode": "A",      
      "usernames": arryUser
      // "usernames": ["cgm.admin01@catsmodish.com"]
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("on check in by qr code : ", response.data);
          console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);
            store.commit("userLogin/SET_MESSAGE_ERROR", "login failure");
            store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            // store.commit("userLogin/SET_MESSAGE_ERROR", "login success");
            // alert( JSON.stringify(response.data, null, 2));
            store.commit("setUser", response.data.firstName);
            store.commit("setToken", response.data.token);
            store.commit("setRoles", response.data.roles);
            store.commit("setIsLogin", true);
            store.commit("SET_LOGIN_KEY", response.data.key);
            statusLogin = true;

            store.commit("userLogin/SET_MESSAGE_ERROR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            // console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("userLogin/SET_MESSAGE_ERROR", "error request");
            statusLogin = false;
            return false;
            // this.$router.push({ path: "/" });
          }

          // save token

          // router to home

          // console.log;
          // alert(this.errorMsg);
          // this.$router.push({ name: "home" });
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },

  
  // https://dev.catsmodish.com/api/v1/virtual/qrcode-image?username=account@promptevent.co.th
  // API_QR_04 Get QRCode image by username
  async getQRcodeByUser(username) {
    const url = store.getters.url + "/api/v1/virtual/qrcode-image?username="+username;
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      // "username": user.username,
      // "password": user.password,
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    let img = null;

    axios
      .get(url, config)
      .then((response) => {
        
          // alert(response.data);
        // your code
        if (response.data.status == "success") {
          console.log(
            "response.data : " + JSON.stringify(response.data, null, 2)
          );

          // alert(response.data);

          img = response.data;
        }
        // this.articleId = response.data.token;
      }, console.log)
      .catch(console.log);

    // return {test : "test" };
    
    // alert(img);
    return img;
  },


  // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
  // API_CF_06 Get conference stream list
  getlistStream() {
    const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      // "username": user.username,
      // "password": user.password,
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    let steams = [];

    axios
      .get(url, config)
      .then((response) => {
        // your code
        if (response.data.status == "success") {
          console.log(
            "response.data : " + JSON.stringify(response.data, null, 2)
          );
          store.commit("setSteams", response.data.steams);
          steams = response.data.steams;
        }
        // this.articleId = response.data.token;
      }, console.log)
      .catch(console.log);

    // return {test : "test" };
    return steams;
  },

  // update strem
  // https://service.promptevent.com/api/v1/virtual/conference-stream-update
  // API_CF_09 Update conference stream details

  updateChannel(stremInput) {
    const url = store.getters.url + "/api/v1/virtual/conference-stream-update";

    // console.log("url : " + JSON.stringify(url, null, 2));
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      name: stremInput.name,
      description: stremInput.description,
      beginDate: stremInput.beginDate,
      endDate: stremInput.endDate,
      streamName: stremInput.streamName,
      // streamUrl: "",
      streamPosterFileName: stremInput.streamPosterFileName,
      streamPosterBase64: stremInput.streamPosterBase64,
      enabled: stremInput.enabled,
    };

    if (stremInput.streamUrl != null && stremInput.streamUrl != "") {
      bodyParameters.streamUrl = stremInput.streamUrl;
    }

    // streamPosterFileName: stremInput.streamPosterFileName,
    //     streamPosterBase64: stremInput.streamPosterBase64,

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    axios
      .post(url, bodyParameters, config)
      .then(
        //   (response) => {
        //   // your code
        //   // this.articleId = response.data.token;
        //   console.log(
        //     "userList : " + JSON.stringify(response.data.users, null, 2)
        //   );
        //   this.userList = response.data.users;
        //   this.totalPage = response.data.totalPage;
        // },
        console.log
      )
      .catch(console.log);
  },

  // update status strem
  // https://service.promptevent.com/api/v1/virtual/conference-stream-live
  // API_CF_07 Conference stream live [start/stop]

  async updateStatusChannel(Channel, cmd) {
    const url = store.getters.url + "/api/v1/virtual/conference-stream-live";
    let message = "";
    // console.log("url : " + JSON.stringify(url, null, 2));
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      streamName: Channel,
      command: cmd,
    };

    // streamPosterFileName: stremInput.streamPosterFileName,
    //     streamPosterBase64: stremInput.streamPosterBase64,

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      .then(
        //   (response) => {
        //   // your code
        //   // this.articleId = response.data.token;
        //   console.log(
        //     "userList : " + JSON.stringify(response.data.users, null, 2)
        //   );
        //   this.userList = response.data.users;
        //   this.totalPage = response.data.totalPage;
        // },
        console.log
      )
      .catch(console.log);

    //   await axios
    //   .post(url, bodyParameters, config)
    //   .then(
    //       (response) => {
    //           // your code
    //           message = response.data.statusDesc;
    //       },

    //       // this.$router.push({ path: "/" })
    //       console.log)
    //   .catch(
    //       console.log);
    return message;
  },
};
