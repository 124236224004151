
import axios from "axios";
import { store } from "@/store/store";

export default {


    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
        const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
        const token = store.getters.token;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        let bodyParameters = {
            // "username": user.username,
            // "password": user.password,

        };


        console.log("url : " + JSON.stringify(url, null, 2));
        console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        console.log("config : " + JSON.stringify(config, null, 2));

        let steams = [];

        axios
            .get(url, config)
            .then(
                (response) => {
                    // your code
                    if (response.data.status == "success") {
                        console.log("response.data : " + JSON.stringify(response.data, null, 2));
                        store.commit("setSteams", response.data.steams);
                        steams = response.data.steams
                    }
                    // this.articleId = response.data.token;
                },
                console.log)
            .catch(console.log);


        // return {test : "test" };
        return steams;
    },


    // update strem
    // https://service.promptevent.com/api/v1/virtual/conference-stream-update
    // API_CF_09 Update conference stream details

    updateChannel(stremInput) {
        const url = store.getters.url + "/api/v1/virtual/conference-stream-update";
  
        // console.log("url : " + JSON.stringify(url, null, 2));
        const token = store.getters.token;
  
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-conference-code": store.getters.conferenceCode,
            "Content-Type": store.getters.ContentType,
          },
        };
  
        let bodyParameters = {
            name: stremInput.name,
            description: stremInput.description,
            beginDate: stremInput.beginDate,
            endDate: stremInput.endDate,
            streamName: stremInput.streamName,
            // streamUrl: "",
            streamPosterFileName: stremInput.streamPosterFileName,
            streamPosterBase64: stremInput.streamPosterBase64,
            enabled: stremInput.enabled,
        };

        if(stremInput.streamUrl != null && stremInput.streamUrl != ""){
            
            bodyParameters.streamUrl = stremInput.streamUrl;
        }

        // streamPosterFileName: stremInput.streamPosterFileName,
        //     streamPosterBase64: stremInput.streamPosterBase64,

        
  
        console.log("url : " + JSON.stringify(url, null, 2));
        console.log(
          "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
        );
        console.log("config : " + JSON.stringify(config, null, 2));
  
        axios
          .post(url, bodyParameters, config)
          .then(
            //   (response) => {
            //   // your code
            //   // this.articleId = response.data.token;
            //   console.log(
            //     "userList : " + JSON.stringify(response.data.users, null, 2)
            //   );
            //   this.userList = response.data.users;
            //   this.totalPage = response.data.totalPage;
            // },
            console.log
          )
          .catch(console.log);
      },

      
    // update status strem
    // https://service.promptevent.com/api/v1/virtual/conference-stream-live
    // API_CF_07 Conference stream live [start/stop]

    async updateStatusChannel(Channel,cmd) {
        const url = store.getters.url + "/api/v1/virtual/conference-stream-live";
        let message="";
        // console.log("url : " + JSON.stringify(url, null, 2));
        const token = store.getters.token;
  
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-conference-code": store.getters.conferenceCode,
            "Content-Type": store.getters.ContentType,
          },
        };
  
        let bodyParameters = {

            streamName: Channel,
            command: cmd
        };

        // streamPosterFileName: stremInput.streamPosterFileName,
        //     streamPosterBase64: stremInput.streamPosterBase64,

        
  
        console.log("url : " + JSON.stringify(url, null, 2));
        console.log(
          "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
        );
        console.log("config : " + JSON.stringify(config, null, 2));
  
        await axios
          .post(url, bodyParameters, config)
          .then(
            //   (response) => {
            //   // your code
            //   // this.articleId = response.data.token;
            //   console.log(
            //     "userList : " + JSON.stringify(response.data.users, null, 2)
            //   );
            //   this.userList = response.data.users;
            //   this.totalPage = response.data.totalPage;
            // },
            console.log
          )
          .catch(console.log);

        //   await axios
        //   .post(url, bodyParameters, config)
        //   .then(
        //       (response) => {
        //           // your code
        //           message = response.data.statusDesc;
        //       },

        //       // this.$router.push({ path: "/" })
        //       console.log)
        //   .catch(
        //       console.log);
      return message;
      },
  
  


}