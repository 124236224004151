
<template>


  <div class="card text-center m-3" v-if="isAdmin">
    
    <!-- {{ isAdmin }} -->

    <b-navbar centered>
      <template #start centered>
        <b-navbar-item  @click.native="goHomePage()"> Home </b-navbar-item>
        <b-navbar-item  @click.native="goAdminUser()">
          User Management
        </b-navbar-item>
        <b-navbar-item @click.native="goSummayQuestion()">
          Summay Question
        </b-navbar-item>
        <b-navbar-item  @click.native="goSummayPoll()">
          Summay Poll
        </b-navbar-item>
        <b-navbar-item  @click.native="goSummayEA()">
          Summay Evaluation
        </b-navbar-item>
      
        <b-navbar-dropdown label="Report">
        <b-navbar-item  @click.native="goReportLiveViewer()">        Report Live Streaming Viewer       </b-navbar-item>
        <b-navbar-item  @click.native="goReportPlayBackViewer()">         Report Playback Streaming Viewer        </b-navbar-item>
        
        <b-navbar-item  @click.native="goReportQA()">        Report Stream Question & Answer      </b-navbar-item>
        
        <b-navbar-item  @click.native="goReportPoll()">       Report Stream Poll    </b-navbar-item>
        
        <b-navbar-item  @click.native="goReportEvaluation()">      Report Conference Evaluation       </b-navbar-item>
        
        </b-navbar-dropdown> 
        <!-- <b-navbar-item href="#" @click="goSetting()">
          Setting
        </b-navbar-item> -->
        
        <!-- <b-navbar-item href="#"> Documentation </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item loading href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown> -->
      </template>

      <template #end> </template>
    </b-navbar>
  </div>
  <!-- </div> -->
</template>

<script>
// import { store } from "@/store/store";

export default {
  name: "adminNav",
  components: {},
  data() {
    return {};
  },
  async created() {},
  computed: {
    isAdmin() {
      let roles = this.$store.getters.roles;
      let adminFlag = false;
      for (let role in roles) {
        console.log("role : " + roles[role]);
        if (roles[role] == "ADMIN") {
          adminFlag = true;
        }
      }

      return adminFlag;
    },
  },

  methods: {
    goAdminUser() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/AdminUserPage" });
    },

    goScanQRCODEPage(){
      this.$router.push({ path: "/ScanQRCODEPage" });

    },
   
    goReportLiveViewer(){
      this.$router.push({ path: "/ReportLiveStreamingViewerPage" });

    },

    
    
    goReportPlayBackViewer(){
      this.$router.push({ path: "/ReportPlayBackViewerPage" });

    },
    
    goReportQA(){
      this.$router.push({ path: "/ReportQAPage" });

    },
    
    goReportPoll(){
      this.$router.push({ path: "/ReportPollPage" });

    },

    goReportEvaluation(){
      this.$router.push({ path: "/ReportEvaluationPage" });

    },

    
    goHomePage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/HomePage" });
      // this.$router.push({ path: "/HomePage" });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },

    goSummayQuestion() {
      this.$router.push({ path: "/SummaryQuestionPage" });
    },

    goSummayPoll() {
      this.$router.push({ path: "/SummaryPollPage" });
    },

    goSummayEA() {
      this.$router.push({ path: "/SummaryEvaluationPage" });
    },
    

    mounted() {
      console.log("mounted");
    },
  },
};
</script>
<style scoped>
</style>
