<template>
  <div >

<html>
  <head>
    <!-- <meta http-equiv="refresh" content="0; url='https://www.w3docs.com'" /> -->

    <!-- DEMO LINK -->
    <!-- <meta http-equiv="refresh" content="0; url='https://docs.google.com/forms/d/1HAmtD5uEaqcjEOSybaWBIzLmM3OPNPRNBDA2JUQYhn4/edit" /> -->
    
    <!-- Production LINK -->
    <!-- <meta http-equiv="refresh" content="0; url='https://forms.gle/rPgoceDLeoV7s16P9" /> -->
    <!-- <meta http-equiv="refresh" content="0; url='https://docs.google.com/forms/d/e/1FAIpQLSdxnl3eRppzsi8FGqyCgm6hL8P9f70EV8gfvVLxOmLWmyzjXQ/viewform" /> -->
    <meta http-equiv="refresh" content="0; url='https://forms.gle/8NMLLddDYq76y4dy8" />
    
  </head>
</html>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },

  data(){

    return {
      // url : "https://docs.google.com/forms/d/1HAmtD5uEaqcjEOSybaWBIzLmM3OPNPRNBDA2JUQYhn4/edit"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
